<template>
    <v-row no-gutters justify="center">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <!-- NOTE: we use this app-splash-loader instead of v-progress-circular because we want this to look exactly like the index.html splash loader so the visual transition is smooth for the user as the rest of the UI loads -->
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="interactionError">
                <v-alert type="error">
                    We cannot process this request.
                </v-alert>
            </template>
            <template v-if="errorUnauthorizedInteraction">
                <v-alert type="error">
                    Unauthorized
                </v-alert>
                <p class="text-h5 font-weight-light mt-6">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                <p class="text-body-1 font-weight-light text-center"><a href="#" @click="navigateToFront">Start over</a></p>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #F5F5F5; /* grey lighten-4 */
    border-top: 4px solid #BDBDBD; /* grey lighten-1 */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { client } from '@/client';

// constants
const IA_LOGIN = 'login';
const IA_SIGNUP = 'signup';
const INTENT_CONNECT = 'connect';
const INTENT_LOGIN = 'login';
const INTENT_SIGNUP = 'signup';
const ROUTE_FRONT = 'front';
// const ROUTE_CONNECT = 'connect';
// const ROUTE_LOGIN = 'login';
// const ROUTE_SIGNUP = 'signup';

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        intent: null, // login or signup
        intentParams: null,
        email: false, // login and signup interactions may include an email address that we need to forward to next step
        productId: null, // signup interaction may include a product id that we need to forward to next step
        productPriceId: null, // signup interaction may include a product price id that we need to forward to next step
        productGroupId: null, // signup interaction may include a product group id that we need to forward to next step
        interactionId: null,
        interactionError: false,
        errorUnauthorizedInteraction: false,
        serverError: false,
        errorTimeout: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.serverError || this.interactionError || this.errorUnauthorizedInteraction;
        },
    },
    methods: {
        async init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.$route.query.cryptium_id_token) {
                await this.resumeFromCryptiumId(this.$route.query.cryptium_id_token);
            }
            // TODO: etherlink connection for authenticated messaging
            // if (this.$route.query.etherlink_profile_token) {
            //     await this.resumeFromEtherLink(this.$route.query.etherlink_profile_token);
            // }
            if (this.$route.query.token) {
                await this.resumeInteraction(this.$route.query.token);
            }
            if (this.interactionId === null && this.$route.query.i) {
                this.interactionId = this.$route.query.i;
            }
            if (this.interactionId) {
                await this.loadInteraction();
            }
            if (this.intent === null && this.$route.query.intent) {
                this.intent = this.$route.query.intent;
            }
            if (this.intent) {
                this.resumeIntent(this.intent);
            }
            this.isViewReady = true;
        },
        /**
         * Called when user returns from Cryptium ID with `cryptium_id_token`
         */
        async resumeFromCryptiumId(token) {
            try {
                this.$store.commit('loading', { resumeFromCryptiumId: true });
                const request = {
                    cryptium_id_token: token,
                };
                console.log(`resumeFromCryptiumId request ${JSON.stringify(request)}`);
                let response;
                if (this.brandprofile) {
                    response = await this.$client.site(this.brandprofile).authn.checkVerifyEmail(request);
                } else {
                    console.error('Interaction.vue: resumeFromCryptiumId called without brandprofile');
                    // response = await this.$client.main().authn.checkVerifyEmail(request);
                }
                console.log(`resumeFromCryptiumId response ${JSON.stringify(response)}`);
                const { type } = response;
                switch (type) {
                case 'fault': {
                    this.serverError = true;
                    this.errorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    break;
                }
                case 'intent': {
                    const { intent, intent_params: intentParams } = response;
                    this.intent = intent;
                    this.intentParams = intentParams;
                    break;
                }
                default:
                    console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                    this.serverError = true;
                    this.errorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('interaction.vue: failed to verify email', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.errorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.errorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        // TODO: change to unknownError?
                        this.serverError = true;
                        this.errorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    // TODO: change to unknownError?
                    this.serverError = true;
                    this.errorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { resumeFromCryptiumId: false });
            }
        },
        async resumeInteraction(token) {
            try {
                this.$store.commit('loading', { resumeInteraction: true });
                const response = await this.$client.site(this.brandprofile).interaction.resume(token);
                console.log(`interaction.vue: resumeInteraction response: ${JSON.stringify(response)}`);

                // reload session after activating the token; it may have authenticated the user
                await this.$store.dispatch('refresh', { force: true });

                const { interaction_id: interactionId, next_route: nextRoute, error } = response;
                if (error) {
                    this.interactionError = true;
                } else if (nextRoute) {
                    console.log(`interaction.vue: resumeInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;

                    // since the destination route is the same, we expect all the state to remain,
                    // but put it in the URL just in case it is cleared by the navigation
                    const query = {
                        i: interactionId,
                        email: this.email,
                        product_id: this.productId,
                        product_price_id: this.productPriceId,
                        product_group_id: this.productGroupId,
                    };

                    // this.$router.replace({ name: 'interaction', query });
                    console.log('interaction.vue: replacing query parameters');
                    if (this.brandprofile) {
                        this.$nav.push({ name: 'brand-interaction', query });
                    } else {
                        this.$nav.push({ name: 'interaction', query });
                    }
                }
            } catch (err) {
                console.error('interaction.vue: failed to activate token', err);
            } finally {
                this.$store.commit('loading', { resumeInteraction: false });
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$store.dispatch('loadInteraction', this.interactionId);
                // const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`interaction.vue: loadInteraction response: ${JSON.stringify(response)}`);
                const { type, next, state } = response;
                console.log(`interaction.vue: loadInteraction: interaction type: ${type} next: ${next} state ${JSON.stringify(state)}`);
                // switch (type) {
                // case 'create_organization':
                //     this.createOrganization({ type, next, state }, prevInteraction);
                //     break;
                // default:
                //     console.error('interaction.vue: unknown interaction type: %s', type);
                //     this.interactionError = true;
                //     break;
                // }
                let intent = null;
                switch (type) {
                case IA_LOGIN:
                    intent = INTENT_LOGIN;
                    break;
                case IA_SIGNUP:
                    intent = INTENT_SIGNUP;
                    if (state.email) {
                        this.email = state.email;
                    }
                    if (state.product_id) {
                        this.productId = state.product_id;
                    }
                    if (state.product_price_id) {
                        this.productPriceId = state.product_price_id;
                    }
                    if (state.product_group_id) {
                        this.productGroupId = state.product_group_id;
                    }
                    // this.display_name = state.display_name;
                    break;
                default:
                    console.error(`interaction.vue: unexpected interaction type: ${JSON.stringify(type)}`);
                    break;
                }
                if (intent) {
                    this.intent = intent;
                }
            } catch (err) {
                console.error('interaction.vue: failed to activate token', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        resumeIntent(intent) {
            // query parameter values may be null depending on how user arrived
            const query = {};
            if (this.email) {
                query.email = this.email;
            }
            if (this.productId) {
                query.product_id = this.productId;
            }
            if (this.productPriceId) {
                query.product_price_id = this.productPriceId;
            }
            if (this.productGroupId) {
                query.product_group_id = this.productGroupId;
            }
            if (this.interactionId) {
                query.i = this.interactionId;
            }
            query.t = Date.now();
            switch (intent) {
            case INTENT_CONNECT:
                console.log('interaction.vue: resumeIntent: redirecting to connect');
                // TODO: when we have custom hostnames we won't need all the brandprofile conditionals and duplicate sets of routes... brandprofile will be determined by hostname and we can just have simple routes
                // this.$router.replace({ name: ROUTE_CONNECT, query });
                if (this.brandprofile) {
                    this.$router.replace({ name: 'brand-connect', params: { brandprofile: this.brandprofile }, query });
                } else {
                    this.$router.replace({ name: 'connect', query });
                }
                break;
            case INTENT_LOGIN:
                console.log('interaction.vue: resumeIntent: redirecting to login');
                // this.$router.replace({ name: ROUTE_LOGIN, query });
                if (this.brandprofile) {
                    this.$router.replace({ name: 'brand-login', params: { brandprofile: this.brandprofile }, query });
                } else {
                    this.$router.replace({ name: 'login', query });
                }
                break;
            case INTENT_SIGNUP:
                // TODO: when we have custom hostnames we won't need all the brandprofile conditionals and duplicate sets of routes... brandprofile will be determined by hostname and we can just have simple routes
                console.log('interaction.vue: resumeIntent: redirecting to signup');
                if (this.intentParams) {
                    query.email = this.intentParams.email;
                    query.product_id = this.intentParams.product_id;
                    query.product_price_id = this.intentParams.product_price_id;
                    query.product_group_id = this.intentParams.product_group_id;
                }
                query.step = 'display_name';
                if (this.brandprofile) {
                    this.$router.replace({ name: 'brand-transaction-signup', params: { brandprofile: this.brandprofile }, query });
                } else {
                    this.$router.replace({ name: 'main-transaction-signup', query });
                }
                break;
            default:
                console.log('interaction.vue: resumeIntent: redirecting to front');
                this.$router.replace({ name: ROUTE_FRONT, query });
                break;
            }
        },
        navigateToFront() {
            console.log('interaction.vue: navigateToFront: redirecting to front');
            // TODO: when we have custom hostnames for all organizations then all routes in a custom hostname will be the "plain" type like 'front', 'connect', etc. because we'll know the brandprofile from the hostname, so we can replace use of this function with a simple <router-link :to="{ name: 'front' }">Start over</router-link> in the html template section
            if (this.brandprofile) {
                this.$nav.push({ name: 'brand-front' });
            } else {
                this.$nav.push({ name: 'front' });
            }
        },
    },
    // created() {
    //     if (this.$route.query.error) {
    //         console.log(`interaction.vue: created, error: ${this.$route.query.error}`);
    //         this.loading = false;
    //         switch (this.$route.query.error) {
    //         case 'unauthorized':
    //             this.errorUnauthorizedInteraction = true;
    //             break;
    //         default:
    //             this.interactionError = true;
    //         }
    //     } else {
    //         this.interactionId = this.$route.query.i;
    //         console.log(`interaction.vue: created, interactionId: ${this.interactionId}`);
    //     }
    // },
    mounted() {
        console.log('interaction.vue: mounted');
        this.init();
    },
};
</script>
